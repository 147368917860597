import Modal from 'component/modal';

const Screenshot_Capture = {
    device_list: {
        videoinput: [],
        videooutput: []
    },

    options: {
        onSubmit: null,
        onCancel: null
    },

    start: function(options) {
        this.options.onSubmit = options.onSubmit || null;
        this.options.onCancel = options.onCancel || null;

        this.getDevice(this.startCapture.bind(this));
    },

    getDevice: function(callback) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            callback();
            return;
        }

        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                devices.forEach((device) => {
                    switch (device.kind) {
                        case 'videoinput':
                            this.device_list.videoinput.push(device);
                            break;
                        case 'videooutput':
                            this.device_list.videooutput.push(device);
                            break;
                    }
                });

                callback();
            })
            .catch(function(error) {
            });
    },

    startCapture: function() {
        var displayMediaOptions = {
            video: {
                cursor: false
            },
            audio: false,
            preferCurrentTab: true,
            monitorTypeSurfaces: 'exclude',
            selfBrowserSurface: 'include'
        };

        try {
            navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
                .then((stream) => {
                    stream.getTracks()[0].onended = () => {
                        this.stopCapture();
                    };

                    var video_element = document.createElement('video');
                    video_element.setAttribute('autoplay', 1);
                    video_element.style.width = '100%';
                    video_element.srcObject = stream;

                    video_element.addEventListener('play', () => {
                        var track = video_element.srcObject.getVideoTracks()[0];
                        var track_settings = track.getSettings();
                        var { width, height } = track_settings;

                        var canvas = document.createElement('canvas');
                        var context = canvas.getContext('2d');

                        canvas.width  = width;
                        canvas.height = height;

                        // wait for the screen select prompt screen to minimize
                        setTimeout(() => {
                            context.drawImage(video_element, 0, 0, width, height);

                            var image_data_uri = canvas.toDataURL('image/png', 1);

                            stream.getTracks().forEach(function(track) {
                                track.stop();
                            });

                            this.options.onSubmit(image_data_uri);
                        }, 0);
                    });
                })
                .catch(this.handleCaptureError.bind(this));
        } catch (e) {
            this.handleCaptureError(e);
        }
    },

    stopCapture: function() {
        this.options.onCancel();
    },

    handleCaptureError: function(e) {
        if (typeof e.name !== 'undefined' && e.name === 'NotAllowedError') {
            if (e.message.toLowerCase() === 'permission denied by system') {
                Modal.modalConfirm('<div style="margin-bottom: 8px;"><b>Permission denied</b></div><div class="userback-modal-info-text">Please allow screen recording permission for the browser.</div>', function() {
                    Modal.modalClose();
                }, 'OK');
            }

            // browser permission is not allowed
            this.stopCapture();
        } else {
            // when video recording (browser API) is not supported in the browser
            Modal.modalConfirm('<div style="margin-bottom: 8px;"><b>Native browser screenshot is not supported in your browser</b></div><div class="userback-modal-info-text">Please download the latest version of <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/firefox/download/thanks/">Firefox</a> or <a href="http://microsoft.com/en-us/edge">Microsoft Edge</a>.</div>', function() {
                Modal.modalClose();
            }, 'OK');
        }
    }
};

export default Screenshot_Capture;
